/* eslint-disable @next/next/no-img-element */
import ProductImage from "components/ui/ProductImage";
import React, { useEffect, useState } from "react";
import { classNames } from "util/common";

export function ProductImageCarousel({
	product,
	imgUrl,
	images,
	gridUI,
	toggleItemsCopyIsOpen = () => {},
}) {
	const [listOfImages, setListOfImages] = useState([]);
	const [currentImage, setCurrentImage] = useState("");

	useEffect(() => {
		if (!!imgUrl) {
			if (!!images) {
				setListOfImages([imgUrl, ...images]);
				setCurrentImage(imgUrl);
			} else {
				setListOfImages([imgUrl]);
				setCurrentImage(imgUrl);
			}
		} else {
			setListOfImages([...images]);
			setCurrentImage(images[0]);
		}
	}, [imgUrl, images]);

	const nextImage = () => {
		const currentIndex = listOfImages.indexOf(currentImage);
		const nextIndex = currentIndex + 1;
		if (nextIndex < listOfImages.length) {
			setCurrentImage(listOfImages[nextIndex]);
		} else {
			setCurrentImage(listOfImages[0]);
		}
	};

	const previousImage = () => {
		const currentIndex = listOfImages.indexOf(currentImage);
		const nextIndex = currentIndex - 1;
		if (nextIndex >= 0) {
			setCurrentImage(listOfImages[nextIndex]);
		} else {
			setCurrentImage(listOfImages[listOfImages.length - 1]);
		}
	};

	const sliderControl = isLeft => {
		if (isLeft) {
			previousImage();
		} else {
			nextImage();
		}
	};
	return (
		<div>
			<div id="default-carousel" className="relative">
				<ProductImage
					product={product}
					src={currentImage}
					onClick={gridUI && toggleItemsCopyIsOpen}
					className={classNames("w-full")}
				/>
				{listOfImages?.length > 1 ? (
					<>
						<button
							type="button"
							className="absolute top-0 left-0.5 z-2 flex items-center justify-center h-full cursor-pointer group focus:outline-none"
							data-carousel-prev=""
							onClick={() => sliderControl(true)}
						>
							<span className="inline-flex items-center justify-center w-4 h-4 rounded-full sm:w-5 sm:h-5 bg-white/5 group-hover:bg-white/20 ">
								<svg
									aria-hidden="true"
									className="w-5 h-5 text-dark sm:w-6 sm:h-6 "
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M15 19l-7-7 7-7"
									></path>
								</svg>
								<span className="sr-only">Previous</span>
							</span>
						</button>
						<button
							type="button"
							className="absolute top-0 right-0.5 z-2 flex items-center justify-center h-full cursor-pointer group focus:outline-none"
							data-carousel-next=""
							onClick={() => sliderControl(false)}
						>
							<span className="inline-flex items-center justify-center w-4 h-4 rounded-full sm:w-5 sm:h-5 bg-white/5 group-hover:bg-white/20">
								<svg
									aria-hidden="true"
									className="w-5 h-5 text-dark sm:w-6 sm:h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M9 5l7 7-7 7"
									></path>
								</svg>
								<span className="sr-only">Next</span>
							</span>
						</button>
					</>
				) : null}
			</div>
		</div>
	);
}
