import React from "react";
import { ModalDialog } from "./ModalDialog";

function ProductViewModal({
	modalViewProduct,
	setModalViewProduct,
	getRowHiddenContent,
}) {
	if (!modalViewProduct?.isOpen || !modalViewProduct?.item) return null;

	return (
		<ModalDialog
			isOpen={modalViewProduct.isOpen}
			onClose={() =>
				setModalViewProduct({ isOpen: false, item: null, history: [] })
			}
			showSubmitButton={false}
			className="z-50"
			gridStylePopUp={true}
			cancelBtnText="סגירה"
			closeOnClickOutside={true}
		>
			{!!modalViewProduct?.history.length && (
				<div className="flex gap-1 text-jane-400 text-sm">
					{modalViewProduct.history.map((item, index) => (
						<>
							<span
								className="underline cursor-pointer "
								onClick={() => {
									setModalViewProduct(prevState => ({
										isOpen: true,
										item: prevState.history[index],
										history: prevState.history.slice(0, index),
									}));
								}}
							>
								{item?.heb_name}
							</span>
							{index !== modalViewProduct.history.length - 1 && "-"}
						</>
					))}
				</div>
			)}
			{getRowHiddenContent(
				{
					...modalViewProduct.item,
					main_img_url: modalViewProduct.item.main_img_200_url
						? modalViewProduct.item.main_img_200_url
						: modalViewProduct.item?.main_img_url,
				},
				setModalViewProduct,
			)}
		</ModalDialog>
	);
}

export default ProductViewModal;
