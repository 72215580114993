import { TablePagination } from "./server-table/TablePagination";
import { ProductImageCarousel } from "components/products/ProductImageCarousel";
import { WarningBox } from "components/layout/WarningBox";
import { TableContainer } from "./server-table/TableContainer";
import {
	classNames,
	formatAmount,
	productAlt,
	roundDecimal,
} from "util/common";
import { WBO } from "util/types";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { GiftIcon } from "@heroicons/react/outline";
import { svgIconByType } from "util/types";
import { colorByType } from "util/types";
import AddToCartButton from "components/cart/AddToCartButton";
import { ProductTypeUI, VARIANT } from "components/products/ProductTypeUI";
import { listProductTypes } from "util/types";
import { isComingSoon, isNewInStore } from "util/date";
import LoadingAnimation from "components/ui/LoadingAnimation";

export const GridLayout = ({
	tbl, // useServerTable instance.
	canNextPage = false,
	cart,
	children,
	customActionButton,
	customButtons,
	enableOrders = false,
	error,
	filterValues = [],
	filters = [],
	filtersTitle,
	getRowClassName = r => "",
	hasItems = false,
	hideOnScroll = false,
	hideLayoutButton = false,
	hidePagination = false,
	infiniteScrolling = false,
	is48HoursPast = false,
	isFiltersOpen = false,
	isHomeWidget = false,
	isOrdersEnabled = false,
	isRowSelected,
	isSingleProduct,
	isSingleStore = false,
	isDesiredCart = false,
	isStoreProducts = false,
	isTableView,
	isTableWithMap,
	isWithDiscountsFilter = false,
	isWithLocationButton,
	isWithShippingFilter = false,
	layout,
	loading,
	naked = false,
	noItemsMessage = () => {},
	onFiltersChanged = fv => {},
	onPageSizeChanged = () => {},
	onSearch = q => {},
	pageCount = 0,
	prependContent,
	prependSortByContent = null,
	prependStoreContent,
	prependStoreSortByContent = null,
	searchPlaceholder,
	searchQuery,
	searchable,
	setIsTableView,
	setModalViewProduct = () => {},
	showPriceRange = false,
	subContent,
	subtitle,
	tableRef,
	title,
	uniquePage,
	withGridLayout = false,
	isMainTable = false,
	...props
}) => {
	const { height, width } = useWindowDimensions();

	const checkStorePriceOnDiscount = (
		storePrice,
		catalogPrice,
		isDiscount,
		row,
	) => {
		if (!catalogPrice && !storePrice) return <span>לא ידוע</span>;

		if (!!row?.i_a_f_s && catalogPrice) {
			return <span>₪{formatAmount(catalogPrice)}</span>;
		}

		if (storePrice < catalogPrice) {
			return (
				<div className="text-center flex flex-col items-center">
					<div className="flex text-xl font-semibold">
						{isDiscount && (
							<div className="flex gap-1 items-center text-red-500">
								<GiftIcon className="w-4 h-4 mb-0.5" />
								{row?.discount_title && (
									<span className="flex items-center text-base">
										{row.discount_title}
									</span>
								)}
							</div>
						)}
					</div>
					<div className="flex items-center gap-1 leading-none mr-10">
						<span className="text-green-600">₪{formatAmount(storePrice)}</span>
						<span className="inline-block mx-2 text-[13px] line-through leading-none">
							₪{formatAmount(catalogPrice)}
						</span>
					</div>
				</div>
			);
		}
		return (
			<div className="flex flex-col items-center text-xl font-semibold leading-none">
				{isDiscount && (
					<div className="flex gap-1 items-center text-red-500">
						<GiftIcon className="w-4 h-4 mb-0.5" />
						{row?.discount_title && (
							<span className="flex items-center text-base">
								{row.discount_title}
							</span>
						)}
					</div>
				)}
				<span>{!!storePrice ? "₪" + formatAmount(storePrice) : "-"}</span>
			</div>
		);
	};

	const formatPriceRange = (from, to, isDiscount, catalogPrice, row) => {
		if (!!row?.i_a_f_s && catalogPrice) {
			return <span>₪{formatAmount(catalogPrice)}</span>;
		}

		if (from === to) {
			return (
				<div className="flex items-center text-xl font-semibold">
					₪{from}
					{isDiscount && (
						<span className="flex items-center">
							<GiftIcon className="w-3.5 text-red-500 cursor-pointer hover:text-pink-600 ml-0.5" />
						</span>
					)}
				</div>
			);
		} else {
			return `₪${to} - ₪${from}`;
		}
	};

	return (
		<TableContainer
			tbl={tbl}
			title={title}
			subtitle={subtitle}
			prependStoreContent={prependStoreContent}
			prependStoreSortByContent={prependStoreSortByContent}
			prependContent={prependContent}
			subContent={subContent}
			searchable={searchable}
			onSearch={onSearch}
			onFiltersChanged={onFiltersChanged}
			naked={naked}
			filters={filters}
			filterValues={tbl.filters}
			filtersTitle={filtersTitle}
			hideOnScroll={hideOnScroll}
			isFiltersOpen={isFiltersOpen}
			layout={layout}
			withGridLayout={withGridLayout}
			isHomeWidget={isHomeWidget}
			is48HoursPast={is48HoursPast}
			customButtons={customButtons}
			isDesiredCart={isDesiredCart}
			isSingleStore={isSingleStore}
			isWithDiscountsFilter={isWithDiscountsFilter}
			isWithShippingFilter={isWithShippingFilter}
			isOrdersEnabled={isOrdersEnabled}
			prependSortByContent={prependSortByContent}
			isWithLocationButton={isWithLocationButton}
			isTableWithMap={isTableWithMap}
			searchQuery={searchQuery}
			isTableView={isTableView}
			setIsTableView={setIsTableView}
			isSingleProduct={isSingleProduct}
			searchPlaceholder={searchPlaceholder}
			hideLayoutButton={hideLayoutButton}
			isMainTable={isMainTable}
			{...props}
		>
			{!!error && (
				<div className="my-6">
					<WarningBox {...error} />
				</div>
			)}
			{!hasItems && <div className="text-jane-500">{noItemsMessage()}</div>}
			{!!hasItems && (
				<div>
					<div ref={tableRef}>
						<div className="mx-auto">
							<div
								className={classNames(
									"grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 ",
									width > WBO["xs"] && width < WBO["sm"] && "grid-cols-3",
									width > WBO["md"] && width < WBO["mdlg"] && "grid-cols-4",
								)}
							>
								{uniquePage.map((row, ri) => {
									const isSelected = isRowSelected(ri);

									return (
										<div
											key={ri}
											className={classNames(
												"flex justify-center items-stretch shadow border-gray-300 rounded-md relative bg-white overflow-x-hidden cursor-pointer transition-all hover:shadow-2xl hover:scale-[1.015] hover:z-10 pb-2",
												getRowClassName(row?.original, isSelected),
												row?.original?.is_pinned &&
													"bg-[#FEFCDC] hover:bg-[#FEFCA0]",
											)}
										>
											{!row.original.store_add_datetime &&
											isComingSoon(row.original.created_at) ? (
												<ProductCornerBadge color="bg-[#0F85D7]" text="בקרוב" />
											) : !row.original.in_stock ? (
												<ProductCornerBadge color="bg-gray-500" text="אזל" />
											) : isNewInStore(row.original.store_add_datetime) ? (
												<ProductCornerBadge color="bg-green-800" text="חדש!" />
											) : null}

											<div className="absolute left-3 top-3 z-10">
												<div
													className={classNames(
														"w-5  h-5 font-bold rounded-full shadow-sm",
														colorByType[row?.original?.product_type],
													)}
												>
													<img
														className="w-5 h-5"
														src={svgIconByType[row?.original?.product_type]}
													/>
												</div>
											</div>
											{!!row?.original?.marketer_logo_url && (
												<div className="absolute right-2 top-2 z-10">
													<div>
														<img
															className="w-10 h-10 opacity-80 rounded-full border-white border-4  shadow-sm"
															src={row?.original?.marketer_logo_url}
														/>
													</div>
												</div>
											)}
											<div className="flex justify-between flex-col">
												<div className=" h-auto rounded w-[150px] sm:w-[200px]">
													{
														<ProductImageCarousel
															product={row?.original}
															title={row?.original?.heb_name}
															images={row?.original?.images}
															imgUrl={
																row?.original?.main_img_300_url
																	? row?.original?.main_img_300_url
																	: row?.original?.main_img_url
															}
															alt={productAlt(row?.original)}
															gridUI={true}
															toggleItemsCopyIsOpen={() =>
																setModalViewProduct({
																	isOpen: true,
																	item: {
																		...row.original,
																		storeId: row?.original?.store?.id,
																	},
																	history: [],
																})
															}
														/>
													}
													<div
														className="text-center"
														onClick={() =>
															setModalViewProduct({
																isOpen: true,
																item: {
																	...row.original,
																	storeId: row?.original?.store?.id,
																},
																history: [],
															})
														}
													>
														<div className="font-semibold flex justify-center items-center flex-col gap-1.5">
															<div className="leading-none text-xs h-[36px] items-center flex flex-col justify-center">
																<div
																	className={classNames(
																		"text-lg leading-none p-0 m-0",
																	)}
																>
																	{row?.original?.heb_name}
																</div>
																{row?.original?.eng_name?.length < 15 && (
																	<span
																		className={classNames(
																			"text-lg leading-none p-0 m-0",
																		)}
																	>
																		{row?.original?.eng_name}
																	</span>
																)}
															</div>
															<div className="flex justify-start gap-0.5 items-center">
																<ProductTypeUI
																	productType={listProductTypes.find(
																		productType => {
																			return (
																				productType.label ===
																				row.original.product_type
																			);
																		},
																	)}
																	variant={VARIANT.SMALLTEXT}
																/>
																<ProductTypeUI
																	productType={listProductTypes.find(
																		productType => {
																			return (
																				productType.label ===
																				row.original.category
																			);
																		},
																	)}
																	variant={VARIANT.SMALLTEXT}
																/>
																<ProductTypeUI
																	productType={listProductTypes.find(
																		productType => {
																			return (
																				productType.label ===
																				row.original.family
																			);
																		},
																	)}
																	variant={VARIANT.SMALLTEXT}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="flex flex-col">
													<div className="text-center my-1 flex justify-center text-xl font-semibold items-center min-h-[24px]">
														{showPriceRange
															? formatPriceRange(
																	row?.original?.from_price,
																	row?.original?.to_price,
																	row.original?.is_discount_exists,
																	row?.original?.catalog_price,
																	row.original,
															  )
															: checkStorePriceOnDiscount(
																	isStoreProducts
																		? row?.original?.store_price
																		: row?.original?.from_price ||
																				row?.original?.to_price ||
																				row?.original?.min_price ||
																				row?.original?.store_price,
																	row?.original?.catalog_price,
																	row.original?.is_discount_exists,
																	row.original,
															  )}
													</div>
													{customActionButton ? (
														customActionButton(row.original)
													) : !!enableOrders ? (
														<div>
															<AddToCartButton
																product={row.original}
																storeId={Number(row.original.store.id)}
																type="grid"
																isWidget={isHomeWidget}
															/>
														</div>
													) : null}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					{!hidePagination && !infiniteScrolling && pageCount > 1 && (
						<div>
							<TablePagination
								pageCount={pageCount}
								pageIndex={pageIndex}
								pageSize={pageSize}
								canPreviousPage={canPreviousPage}
								canNextPage={canNextPage}
								onNextClicked={nextPage}
								onPrevClicked={previousPage}
								onPageNumClicked={pn => gotoPage(pn - 1)}
								onPageSizeChanged={onPageSizeChanged}
								disabled={!!loading}
							/>
						</div>
					)}
					{!!loading && (
						<div className="absolute bg-white top-0 left-0 w-full h-full rounded-md bg-opacity-40" />
					)}
					{!!loading && (
						<div className="fixed top-[50%] right-[50%] w-full h-full m-auto z-50 translate-x-2/4">
							{!!infiniteScrolling && !!canNextPage && (
								<>
									{!!loading ? (
										<LoadingAnimation className="h-12 w-12 mx-auto" />
									) : (
										<div className="text-sm text-center mt-2">
											<span
												onClick={() => setFetchingNextPage(true)}
												className="cursor-pointer text-jane-600 hover:text-jane-800 transition-colors py-1 font-medium"
											>
												טען עוד
											</span>
										</div>
									)}
								</>
							)}
						</div>
					)}
				</div>
			)}
		</TableContainer>
	);
};

const ProductCornerBadge = ({ color, text }) => {
	return (
		<span
			className={`${color} w-[120px] text-[12px] text-white text-center absolute rotate-45 z-[11] shadow-md font-semibold bg-opacity-70 origin-top-right top-14 -right-7`}
		>
			{text}
		</span>
	);
};
