import {
	ArrowLeftIcon,
	CheckCircleIcon,
	ChevronLeftIcon,
	MinusSmIcon,
	PlusSmIcon,
} from "@heroicons/react/solid";
import { Button } from "components/form/Button";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { useUserCarts } from "components/jotai/cartAtom";
import JaneRefModal from "components/modals/JaneRefModal";
import { ModalDialog } from "components/modals/ModalDialog";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { BsCartPlusFill } from "react-icons/bs";

const AddToCartButton = ({
	storeId,
	product,
	type = "icon",
	isWidget = false,
	isAbsolute = false,
	duplicates = [],
	enableOrders = false,
	shouldToast = true,
	referral = "Jane product",
}) => {
	const {
		addToCart,
		getProductCountInCart,
		removeFromCart,
		getDuplicatesItemCountInCart,
		userCarts,
		setWidgetOrderModal,
		setWidgetCartModal,
	} = useUserCarts();
	const [openDuplicateItemModal, setOpenDuplicateItemModal] = useState(false);
	const [openDuplicateItemFullModal, setOpenDuplicateItemFullModal] =
		useState(false);
	const [openJaneRefModal, setOpenJaneRefModal] = useState(false);
	const [openJaneRefModalSingleProduct, setOpenJaneRefModalSingleProduct] =
		useState(false);
	const [selectedProductWithBatch, setSelectedProductWithBatch] =
		useState(null);
	const isInIframe = useIsInIframe();
	const router = useRouter();

	const handleRedirectSingleProduct = (product, storeId) => {
		const productIdWithBatchId = `${product.product_id}+${
			product.batch_id ? product.batch_id : "noBatch"
		}+${!!product?.sku_code ? product.sku_code : "noSku"}`;
		const queryToSend = [{ [productIdWithBatchId]: 1 }];
		const queryParams = queryToSend
			.map(item => {
				const key = Object.keys(item)[0];
				const value = item[key];
				return `${encodeURIComponent(`${key}`)}=${encodeURIComponent(value)}`;
			})
			.join("&");

		window.open(
			`/stores/${storeId}?add=true&referral=${referral}&${queryParams}`,
			"_blank",
		);
	};

	const handleRedirectSingleProductBatch = (item, storeId) => {
		const productIdWithBatchId = `${item.product.id}+${
			item.batch_id ? item.batch_id : "noBatch"
		}+${item.sku_code ? item.sku_code : "noSku"}`;
		const queryToSend = [{ [productIdWithBatchId]: 1 }];
		const queryParams = queryToSend
			.map(item => {
				const key = Object.keys(item)[0];
				const value = item[key];
				return `${encodeURIComponent(`${key}`)}=${encodeURIComponent(value)}`;
			})
			.join("&");
		window.open(
			`/stores/${storeId}?add=true&referral=${referral}&${queryParams}`,
			"_blank",
		);
	};

	const getLowStockMessage = (
		quantity,
		lowStockCount,
		showLowStockQuantity,
	) => {
		if (!quantity || !showLowStockQuantity) return;
		if (Number(quantity) <= lowStockCount) {
			return <span className="text-red-500">מלאי נמוך</span>;
		}
	};

	if (type === "icon") {
		const itemsInCart = getProductCountInCart(storeId, product);
		const isSingleStoreIframe =
			isInIframe && referral === "iframe in cannabiz store";
		if (!enableOrders) return;
		return (
			<div className="relative">
				<ModalDialog
					isOpen={openJaneRefModalSingleProduct}
					title={`הזמנה חדשה`}
					showCancelButton={true}
					showSubmitButton={false}
					cancelBtnText="חזרה"
					maxWidth={600}
					onClose={() => {
						setOpenJaneRefModalSingleProduct(false);
					}}
				>
					<div className="flex flex-col justify-center items-center gap-4">
						<img src="/jane-logo.svg" alt="Jane" className="w-32" />
						<div>להשלמת ההזמנה תועברו לאתר ג&apos;יין</div>
						<Button
							primary
							className="w-full gap-1 flex justify-center max-w-[300px]"
							onClick={() => {
								handleRedirectSingleProduct(product, storeId);
							}}
						>
							<span>השלמת הזמנה בג&apos;יין</span>
							<ArrowLeftIcon className="w-3 h-3" />
						</Button>
					</div>
				</ModalDialog>
				<Button
					primary
					className={`${
						isAbsolute ? "absolute -left-[30px] -top-[15px] xs:-top-[12px" : ""
					} ${itemsInCart > 0 && "bg-opacity-50"}`}
					onClick={() => {
						if (isWidget && isInIframe) {
							setOpenJaneRefModalSingleProduct(true);
						} else if (isWidget) {
							handleRedirectSingleProduct(product, storeId);
						} else {
							addToCart(
								storeId,
								product,
								shouldToast,
								referral,
								!isSingleStoreIframe,
							);
						}
					}}
				>
					<BsCartPlusFill className="w-4 h-4 cursor-pointer" />
					{itemsInCart > 0 && (
						<span className="absolute opacity-90 -top-1.5 left-6 bg-red-500 text-red-50 font-bold w-3.5 h-3.5 sm:w-4 sm:h-4 flex justify-center items-center text-[10px] rounded-full">
							{itemsInCart}
						</span>
					)}
				</Button>
			</div>
		);
	}

	if (type === "full") {
		const itemsInCart = getProductCountInCart(storeId, product);
		if (itemsInCart === 0 || isWidget) {
			return (
				<div className="relative flex flex-col gap-1">
					<ModalDialog
						isOpen={openJaneRefModalSingleProduct}
						title={`הזמנה חדשה`}
						showCancelButton={true}
						showSubmitButton={false}
						cancelBtnText="חזרה"
						maxWidth={600}
						onClose={() => {
							setOpenJaneRefModalSingleProduct(false);
						}}
					>
						<div className="flex flex-col justify-center items-center gap-4">
							<img src="/jane-logo.svg" alt="Jane" className="w-32" />
							<div>להשלמת ההזמנה תועברו לאתר ג&apos;יין</div>
							<Button
								primary
								className="w-full gap-1 flex justify-center max-w-[300px]"
								onClick={() => {
									handleRedirectSingleProduct(product, storeId);
								}}
							>
								<span>השלמת הזמנה בג&apos;יין</span>
								<ArrowLeftIcon className="w-3 h-3" />
							</Button>
						</div>
					</ModalDialog>
					{enableOrders && (
						<Button
							onClick={() => {
								if (isWidget && isInIframe) {
									setOpenJaneRefModalSingleProduct(true);
								} else if (isWidget) {
									handleRedirectSingleProduct(product, storeId);
								} else {
									addToCart(storeId, product, shouldToast, referral);
								}
							}}
							primary
							className="flex justify-center gap-1 min-w-[120px]"
						>
							<span className="text-base">הוסף לסל</span>
							<BsCartPlusFill className="w-3 h-3 " />
						</Button>
					)}
					<Link
						href={`${
							isInIframe
								? product.store.biz_url
								: `/stores/${
										product.store?.display_name_eng
											? product.store.display_name_eng
											: product.store.id
								  }`
						}`}
					>
						<a
							target={isInIframe ? "_blank" : "_self"}
							rel="noopener noreferrer"
						>
							<Button className="p-4 min-w-[120px]">
								<span className="text-base font-semibold">לבית המרקחת</span>
								<ChevronLeftIcon className="w-3.5 h-3.5 mr-0.5 -ml-1" />
							</Button>
						</a>
					</Link>
				</div>
			);
		}

		if (itemsInCart > 0 && !isWidget && enableOrders) {
			return (
				<div className="flex flex-col w-full items-center sm:items-start relative">
					<div className="flex flex-col sm:flex-row">
						<div className="flex rounded-md h-[34px]">
							<button
								type="button"
								onClick={() => {
									addToCart(storeId, product, shouldToast, referral);
								}}
								className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 p-2"
							>
								<PlusSmIcon className="w-4 h-4" aria-hidden="true" />
							</button>
							<div className="relative inline-flex items-center border border-gray-300 border-l-0 bg-white text-sm font-medium text-gray-500 px-2">
								{itemsInCart}
							</div>
							<button
								type="button"
								onClick={() => removeFromCart(storeId, product)}
								className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 p-2"
							>
								<MinusSmIcon className="w-4 h-4" aria-hidden="true" />
							</button>
						</div>
						<div className="sm:mr-1 text-gray-400 text-sm flex items-center sm:mt-0 mt-2">
							<CheckCircleIcon className="w-5 h-5 ml-1 text-green-600" />({" "}
							{itemsInCart * 10} גרם )
						</div>
					</div>
					<Link href="/cart" passHref>
						<a target={"_self"} rel="noopener noreferrer">
							<Button
								primary
								className="flex items-center justify-center gap-1 w-[120px] min-h-[34px] my-1"
							>
								<span className="text-base">לסל שלי</span>
								<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
							</Button>
						</a>
					</Link>
					<Link
						href={`${
							isInIframe
								? product.store.biz_url
								: `/stores/${
										product.store?.display_name_eng
											? product.store.display_name_eng
											: product.store.id
								  }`
						}`}
					>
						<a
							target={isInIframe ? "_blank" : "_self"}
							rel="noopener noreferrer"
						>
							<Button className="p-4 min-w-[120px]">
								<span className="text-base font-semibold">לבית המרקחת</span>
								<ChevronLeftIcon className="w-3.5 h-3.5 mr-0.5 -ml-1" />
							</Button>
						</a>
					</Link>
				</div>
			);
		}
	}

	if (type === "singleStore") {
		const cart = userCarts.find(
			cart => Number(cart.storeId) === Number(storeId),
		);
		const itemsInCart = getProductCountInCart(storeId, product);
		if (itemsInCart === 0) {
			return (
				<div className="relative flex gap-1 mt-2 flex-col xs:flex-row w-full">
					<Button
						onClick={() => {
							addToCart(storeId, product, shouldToast, referral, !isInIframe);
						}}
						primary
						className="flex justify-center gap-1 w-[105px]"
					>
						<span className="text-sm">הוסף לסל</span>
						<BsCartPlusFill className="w-4 h-4" />
					</Button>
					{!isWidget && (
						<Link href={`/products/${product.id}`}>
							<Button className="w-[105px] flex justify-center text-sm min-h-[34px]">
								<span className="text-sm">לעמוד מוצר</span>
								<span>
									<ChevronLeftIcon className="w-3.5 h-3.5" />
								</span>
							</Button>
						</Link>
					)}
					{isInIframe && isWidget && (
						<Link
							href={`${
								product.biz_url ? product.biz_url : `/products/${product.id}`
							}`}
						>
							<a target="_blank">
								<Button className="w-[105px] flex justify-center min-h-[34px]">
									<span className="text-sm">לעמוד מוצר</span>
									<span>
										<ChevronLeftIcon className="w-3.5 h-3.5" />
									</span>
								</Button>
							</a>
						</Link>
					)}
				</div>
			);
		}

		if (itemsInCart > 0) {
			return (
				<div className="flex flex-col w-full items-center sm:items-start relative">
					<JaneRefModal
						cart={cart}
						store={cart.store}
						openJaneRefModal={openJaneRefModal}
						setOpenJaneRefModal={setOpenJaneRefModal}
						referral={referral}
					/>
					<div className="flex">
						<div className="flex rounded-md h-[34px]">
							<button
								type="button"
								onClick={() => {
									addToCart(
										storeId,
										product,
										shouldToast,
										referral,
										!isInIframe,
									);
								}}
								className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 p-2"
							>
								<PlusSmIcon className="w-4 h-4" aria-hidden="true" />
							</button>
							<div className="relative inline-flex items-center border border-gray-300 border-l-0 bg-white text-sm font-medium text-gray-500 px-2">
								{itemsInCart}
							</div>
							<button
								type="button"
								onClick={() => removeFromCart(storeId, product)}
								className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 p-2"
							>
								<MinusSmIcon className="w-4 h-4" aria-hidden="true" />
							</button>
						</div>
						<div className="sm:mr-1 text-gray-400 text-sm flex items-center sm:mt-0 mt-2">
							<CheckCircleIcon className="w-5 h-5 ml-1 text-green-600" />({" "}
							{itemsInCart * 10} גרם )
						</div>
					</div>
					<div className="flex gap-1 mt-2 flex-col xs:flex-row w-full">
						{isInIframe ? (
							<Button
								primary
								className="flex items-center justify-center gap-1 w-[105px] min-h-[34px] text-sm"
								onClick={() => setOpenJaneRefModal(true)}
							>
								<span className="text-sm">לסל שלי</span>
								<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
							</Button>
						) : isWidget ? (
							<Button
								primary
								className="flex items-center justify-center gap-1 w-[105px] min-h-[34px] text-sm"
								onClick={() => {
									setWidgetCartModal(true);
								}}
							>
								<span className="text-sm">לסל שלי</span>
								<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
							</Button>
						) : (
							<Link href="/cart" passHref>
								<a target={"_self"} rel="noopener noreferrer">
									<Button
										primary
										className="flex items-center justify-center gap-1 w-[105px] min-h-[34px] text-sm"
									>
										<span className="text-sm">לסל שלי</span>
										<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
									</Button>
								</a>
							</Link>
						)}
						{!isWidget && (
							<Link href={`/products/${product.id}`}>
								<Button className="text-sm w-[105px] min-h-[34px]">
									<span className="text-sm">לעמוד מוצר</span>
									<ChevronLeftIcon className="w-3.5 h-3.5" />
								</Button>
							</Link>
						)}
						{isInIframe && isWidget && (
							<Link
								href={`${
									product.biz_url ? product.biz_url : `/products/${product.id}`
								}`}
							>
								<a target="_blank">
									<Button className="text-sm w-[105px] flex min-h-[34px]">
										<span className="text-sm">לעמוד מוצר</span>
										<ChevronLeftIcon className="w-3.5 h-3.5" />
									</Button>
								</a>
							</Link>
						)}
					</div>
				</div>
			);
		}
	}

	if (type === "duplicates") {
		const storeDuplicates = duplicates;

		const itemsInCart = getDuplicatesItemCountInCart(
			storeId,
			product.product.id,
		);

		return (
			<div className="relative">
				<ModalDialog
					isOpen={openJaneRefModalSingleProduct}
					title={`הזמנה חדשה`}
					showCancelButton={true}
					showSubmitButton={false}
					cancelBtnText="חזרה"
					maxWidth={600}
					onClose={() => {
						setOpenJaneRefModalSingleProduct(false);
						setSelectedProductWithBatch(null);
					}}
				>
					<div className="flex flex-col justify-center items-center gap-4">
						<img src="/jane-logo.svg" alt="Jane" className="w-32" />
						<div>להשלמת ההזמנה תועברו לאתר ג&apos;יין</div>
						<Button
							primary
							className="w-full gap-1 flex justify-center max-w-[300px]"
							onClick={() => {
								handleRedirectSingleProductBatch(
									selectedProductWithBatch,
									storeId,
								);
							}}
						>
							<span>השלמת הזמנה בג&apos;יין</span>
							<ArrowLeftIcon className="w-3 h-3" />
						</Button>
					</div>
				</ModalDialog>
				<ModalDialog
					isOpen={openDuplicateItemModal}
					maxWidth={800}
					onClose={() => setOpenDuplicateItemModal(false)}
					onSubmit={() => {
						setOpenDuplicateItemModal(false);
						router.push({
							pathname: "/cart",
						});
					}}
					className="px-1"
					showSubmitButton={false}
					submitBtnText="לסל שלי"
					cancelBtnText="חזרה"
					title="בחירת מוצר"
					closeOnClickOutside={true}
				>
					<div className="grid grid-cols-5 px-1 bg-white border rounded-t-lg border-b-0">
						<div className="col-span-2">מק״ט/אצווה</div>
						<div className="text-center">ערכים</div>
						<div className="text-center">תוקף</div>
					</div>
					<div className="w-full flex flex-col border rounded-b-lg overflow-hidden">
						{storeDuplicates?.map((item, i) => {
							const itemCount = getProductCountInCart(storeId, item);
							return (
								<div
									className={`grid grid-cols-5 bg-white px-1 w-full py-1 border-b ${
										storeDuplicates.length - 1 === i ? "border-b-0" : "border-b"
									}`}
									key={item.store_product_id}
								>
									<div className="flex col-span-2 items-center text-ellipsis min-w-[75px] flex-wrap break-all">
										<div className="flex flex-col">
											<span>{item.sku_code ? item.sku_code : "-"}</span>
											<span>
												{item.batch?.batch_id ? item.batch?.batch_id : "-"}
											</span>
											<span>
												{getLowStockMessage(
													item.quantity,
													item.store.low_stock_product_quantity,
													item.store.show_low_stock_quantity,
												)}
											</span>
										</div>
									</div>
									<div className="flex flex-col justify-center items-center">
										<span>
											{item?.batch?.percent_thc
												? `${item.batch.percent_thc}%`
												: item.store_percent_thc
												? `${item.store_percent_thc}%`
												: "-"}
										</span>
										<span>
											{item?.batch?.percent_cbd
												? `${item.batch.percent_cbd}%`
												: item.store_percent_cbd
												? `${item.store_percent_cbd}%`
												: "-"}
										</span>
									</div>
									<div className="flex items-center justify-center">
										{item.store_product_date
											? moment(item.store_product_date, "yyyy-mm-dd").format(
													"MM-YY",
											  )
											: "-"}
									</div>

									<div className="flex flex-col w-full items-center sm:items-start justify-center">
										<div className="flex flex-col sm:flex-row">
											{isWidget ? (
												<Button
													onClick={() => {
														if (isInIframe) {
															setSelectedProductWithBatch(item);
															setOpenJaneRefModalSingleProduct(true);
														} else {
															handleRedirectSingleProductBatch(item, storeId);
														}
													}}
												>
													בחירה
												</Button>
											) : (
												<div className="flex rounded-md h-[30px]">
													<button
														type="button"
														onClick={() => {
															addToCart(storeId, item, shouldToast, referral);
														}}
														className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 px-1 py-0.5 text-sm"
													>
														<PlusSmIcon
															className="w-3 h-3 sm:w-4 sm:h-4"
															aria-hidden="true"
														/>
													</button>
													<div className="relative inline-flex items-center border border-gray-300 border-l-0 bg-white text-[11px] font-medium text-gray-500 px-1 py-0.5 text-sm">
														{itemCount}
													</div>
													<button
														type="button"
														disabled={itemCount === 0}
														onClick={() => removeFromCart(storeId, item)}
														className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 px-1 py-0.5 text-sm"
													>
														<MinusSmIcon
															className="w-3 h-3 sm:w-4 sm:h-4"
															aria-hidden="true"
														/>
													</button>
												</div>
											)}
										</div>
										<span>₪{item.store_price}</span>
									</div>
								</div>
							);
						})}
					</div>
				</ModalDialog>
				<div
					onClick={() => {
						setOpenDuplicateItemModal(true);
					}}
				>
					<Button
						primary
						className={`${
							isAbsolute
								? "absolute -left-[30px] -top-[15px] xs:-top-[12px"
								: ""
						}`}
					>
						<BsCartPlusFill className="w-4 h-4 cursor-pointer" />
						{itemsInCart > 0 && (
							<span className="absolute opacity-90 -top-1.5 left-6 bg-red-500 text-red-50 font-bold w-3.5 h-3.5 sm:w-4 sm:h-4 flex justify-center items-center text-[10px] rounded-full">
								{itemsInCart}
							</span>
						)}
					</Button>
				</div>
			</div>
		);
	}

	if (type === "duplicatesFull") {
		const storeDuplicates = duplicates;

		const itemsInCart = getDuplicatesItemCountInCart(
			storeId,
			product.product.id,
		);
		return (
			<>
				<ModalDialog
					isOpen={openJaneRefModalSingleProduct}
					title={`הזמנה חדשה`}
					showCancelButton={true}
					showSubmitButton={false}
					cancelBtnText="חזרה"
					maxWidth={600}
					onClose={() => {
						setOpenJaneRefModalSingleProduct(false);
						setSelectedProductWithBatch(null);
					}}
				>
					<div className="flex flex-col justify-center items-center gap-4">
						<img src="/jane-logo.svg" alt="Jane" className="w-32" />
						<div>להשלמת ההזמנה תועברו לאתר ג&apos;יין</div>
						<Button
							primary
							className="w-full gap-1 flex justify-center max-w-[300px]"
							onClick={() => {
								handleRedirectSingleProductBatch(
									selectedProductWithBatch,
									storeId,
								);
							}}
						>
							<span>השלמת הזמנה בג&apos;יין</span>
							<ArrowLeftIcon className="w-3 h-3" />
						</Button>
					</div>
				</ModalDialog>
				<ModalDialog
					isOpen={openDuplicateItemFullModal}
					maxWidth={800}
					onClose={() => setOpenDuplicateItemFullModal(false)}
					onSubmit={() => {
						setOpenDuplicateItemFullModal(false);
						router.push({
							pathname: "/cart",
						});
					}}
					className="px-1"
					showSubmitButton={false}
					submitBtnText="לסל שלי"
					cancelBtnText="חזרה"
					title="בחירת מוצר"
					closeOnClickOutside={true}
				>
					<div className="grid grid-cols-5 px-1 bg-white border rounded-t-lg border-b-0">
						<div className="col-span-2">מק״ט/אצווה</div>
						<div className="text-center">ערכים</div>
						<div className="text-center">תוקף</div>
						<div className="text-center">מחיר</div>
					</div>
					<div className="w-full flex flex-col border rounded-b-lg overflow-hidden">
						{storeDuplicates?.map((item, i) => {
							const itemCount = getProductCountInCart(storeId, item);
							return (
								<div
									className={`grid grid-cols-5 bg-white px-1 w-full py-1 border-b ${
										storeDuplicates.length - 1 === i ? "border-b-0" : "border-b"
									}`}
									key={item.store_product_id}
								>
									<div className="flex items-center col-span-2 text-ellipsis min-w-[75px] flex-wrap break-all">
										<div className="flex flex-col">
											<span>{item.sku_code ? item.sku_code : "-"}</span>
											<span>
												{item.batch?.batch_id ? item.batch?.batch_id : "-"}
											</span>
											<span>
												{getLowStockMessage(
													item.quantity,
													item.store.low_stock_product_quantity,
													item.store.show_low_stock_quantity,
												)}
											</span>
										</div>
									</div>
									<div className="flex flex-col justify-center items-center">
										<span>
											{item?.batch?.percent_thc
												? `${item.batch.percent_thc}%`
												: item.store_percent_thc
												? `${item.store_percent_thc}%`
												: "-"}
										</span>
										<span>
											{item?.batch?.percent_cbd
												? `${item.batch.percent_cbd}%`
												: item.store_percent_cbd
												? `${item.store_percent_cbd}%`
												: "-"}
										</span>
									</div>
									<div className="flex items-center justify-center">
										{item.store_product_date
											? moment(item.store_product_date, "yyyy-mm-dd").format(
													"MM-YY",
											  )
											: "-"}
									</div>

									<div className="flex flex-col w-full items-center sm:items-start justify-center">
										<div className="flex flex-col sm:flex-row">
											{isWidget ? (
												<Button
													onClick={() => {
														if (isInIframe) {
															setSelectedProductWithBatch(item);
															setOpenJaneRefModalSingleProduct(true);
														}
														handleRedirectSingleProductBatch(item, storeId);
													}}
												>
													בחירה
												</Button>
											) : (
												<div className="flex rounded-md h-[30px]">
													<button
														type="button"
														onClick={() => {
															addToCart(storeId, item, shouldToast, referral);
														}}
														className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 px-1 py-0.5 text-sm"
													>
														<PlusSmIcon
															className="w-3 h-3 sm:w-4 sm:h-4"
															aria-hidden="true"
														/>
													</button>
													<div className="relative inline-flex items-center border border-gray-300 border-l-0 bg-white text-[11px] font-medium text-gray-500 px-1 py-0.5 text-sm">
														{itemCount}
													</div>
													<button
														type="button"
														disabled={itemCount === 0}
														onClick={() => removeFromCart(storeId, item)}
														className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 px-1 py-0.5 text-sm"
													>
														<MinusSmIcon
															className="w-3 h-3 sm:w-4 sm:h-4"
															aria-hidden="true"
														/>
													</button>
												</div>
											)}
										</div>
										<span>₪{item.store_price}</span>
									</div>
								</div>
							);
						})}
					</div>
				</ModalDialog>
				{itemsInCart === 0 && (
					<div className="relative flex flex-col gap-1">
						{enableOrders && (
							<Button
								onClick={() => setOpenDuplicateItemFullModal(true)}
								primary
								className="flex justify-center gap-1 w-[120px]"
							>
								<span className="text-base">הוסף לסל</span>
								<BsCartPlusFill className="w-4 h-4" />
							</Button>
						)}
						<Link
							href={`${
								isInIframe
									? product.store.biz_url
									: `/stores/${
											product.store?.display_name_eng
												? product.store.display_name_eng
												: product.store.id
									  }`
							}`}
						>
							<a
								target={isInIframe ? "_blank" : "_self"}
								rel="noopener noreferrer"
							>
								<Button className="p-4 min-w-[120px]">
									<span className="text-base font-semibold">לבית המרקחת</span>
									<ChevronLeftIcon className="w-3.5 h-3.5 mr-0.5 -ml-1" />
								</Button>
							</a>
						</Link>
					</div>
				)}
				{itemsInCart > 0 && enableOrders && (
					<div className="flex flex-col w-full items-center relative">
						<div className="flex flex-col w-full items-center sm:items-start gap-1">
							<div className="flex flex-col xs:flex-row gap-1">
								<Button
									primary
									className="flex items-center justify-center gap-1 w-[120px] min-h-[34px] "
									onClick={() => {
										setOpenDuplicateItemFullModal(true);
									}}
								>
									<span className="text-base">הוסף לסל</span>
									<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
								</Button>

								<Link href={`/cart`}>
									<Button className="flex items-center justify-center gap-1 w-[120px] min-h-[34px]">
										<span className="text-base">לסל שלי</span>
										<span>
											<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
										</span>
									</Button>
								</Link>
							</div>
							<div className="">
								<Link
									href={`${
										isInIframe
											? product.store.biz_url
											: `/stores/${
													product.store?.display_name_eng
														? product.store.display_name_eng
														: product.store.id
											  }`
									}`}
								>
									<a
										target={isInIframe ? "_blank" : "_self"}
										rel="noopener noreferrer"
									>
										<Button className="p-4 min-w-[120px]">
											<span className="text-base font-semibold">
												לבית המרקחת
											</span>
											<ChevronLeftIcon className="w-3.5 h-3.5 mr-0.5 -ml-1" />
										</Button>
									</a>
								</Link>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}

	if (type === "grid") {
		const cart = userCarts.find(
			cart => Number(cart.storeId) === Number(storeId),
		);
		const itemsInCart = getProductCountInCart(storeId, product);
		if (itemsInCart === 0) {
			return (
				<Button
					onClick={() => {
						addToCart(storeId, product, shouldToast, referral);
					}}
					primary
					className="flex justify-center gap-1 w-full relative px-2 h-[34px]"
				>
					<span className="text-base">הוסף לסל</span>
					<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
				</Button>
			);
		}

		if (itemsInCart > 0) {
			return (
				<div className="flex justify-center w-full px-0 gap-px h-[34px] max-w-[200px] items-center relative">
					<JaneRefModal
						cart={cart}
						store={cart.store}
						openJaneRefModal={openJaneRefModal}
						setOpenJaneRefModal={setOpenJaneRefModal}
					/>
					<div className="flex flex-col sm:flex-row">
						<div className="flex rounded-md h-[34px] ">
							<button
								type="button"
								onClick={() => {
									addToCart(storeId, product, shouldToast, referral);
								}}
								className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 p-1"
							>
								<PlusSmIcon className="w-4 h-4" aria-hidden="true" />
							</button>
							<div className="relative inline-flex items-center border border-gray-300 border-l-0 bg-white text-sm font-medium text-gray-500 px-1">
								{itemsInCart}
							</div>
							<button
								type="button"
								onClick={() => removeFromCart(storeId, product)}
								className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 p-1"
							>
								<MinusSmIcon className="w-4 h-4" aria-hidden="true" />
							</button>
						</div>
					</div>
					{isInIframe ? (
						<Button
							primary
							className="flex items-center justify-center gap-1 w-[105px] min-h-[34px] text-sm"
							onClick={() => setOpenJaneRefModal(true)}
						>
							<span className="text-sm">לסל שלי</span>
							<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
						</Button>
					) : isWidget ? (
						<Button
							primary
							className="flex items-center justify-center gap-1 w-[105px] min-h-[34px] text-sm"
							onClick={() => {
								setWidgetCartModal(true);
							}}
						>
							<span className="text-sm">לסל שלי</span>
							<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
						</Button>
					) : (
						<Link href="/cart" passHref>
							<a target={"_self"} rel="noopener noreferrer">
								<Button
									primary
									className="flex items-center justify-center gap-1  my-1 text-base h-[34px] "
								>
									לסל שלי
									<BsCartPlusFill className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
								</Button>
							</a>
						</Link>
					)}
				</div>
			);
		}
	}
};

export default AddToCartButton;
